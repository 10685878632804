<template>
  <validation-observer ref="simpleRules">
    <customer-form />
    <b-button
      variant="primary"
      :disabled="submitStatus || emailControl.status || phoneControl.status"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CustomerForm from '@/views/Admin/Customers/CustomerForm.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BButton,
    CustomerForm,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
    saveData() {
      return this.$store.getters['customers/dataSaveStatus']
    },
    emailControl() {
      return this.$store.getters['customers/emailControl']
    },
    phoneControl() {
      return this.$store.getters['customers/phoneControl']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('customers/SET_EMAIL_CONTROL', {
      status: false,
      id: null,
    })
    this.getData()
    this.$store.commit('customers/SET_DETAIL_FORM', true)
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.id_countries) {
            this.$store.dispatch('cities/getDataList', {
              select: [
                'cities.id AS id',
                'cities.title AS title',
              ],
              where: {
                'cities.id_countries': res.id_countries,
              },
            })
          }
        })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('customers/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
